<script setup>
import { ref, watch, computed } from "vue";
import { Link, router } from "@inertiajs/vue3";
import debounce from "lodash.debounce";

const props = defineProps({
    profile: Object,
    filters: Object,
    ranks: Array,
    lines: Array,
    departments: Array,
});

const facultyProfiles = computed(() => props.profile.data);
const paginateProfiles = computed(() => props.profile.links);
const departments = computed(() => _.orderBy(props.departments, "name", "asc"));
const ranks = computed(() => props.ranks);
const lines = computed(() => props.lines);
const search = ref(props.filters.search || "");
const selectedRank = ref(props.filters.rank || "");
const selectedLine = ref(props.filters.line || "");
const selectedDepartment = ref(props.filters.department || "");

watch(
    [search, selectedRank, selectedLine, selectedDepartment],
    debounce(
        () => {
            router.get(
                route("trivalley", {
                    search: search.value,
                    rank: selectedRank.value,
                    line: selectedLine.value,
                    department: selectedDepartment.value,
                }),
                {
                    preserveState: true,
                    preserveScroll: true,
                }
            );
        },
        600,
        { leading: false }
    )
);
</script>

<template>
    <div class="bg-transparent">
        <!-- Filter and Search Bar -->
        <div class="pt-12">
            <div class="max-w-screen-2xl mx-auto sm:px-6 lg:px-8">
                <div
                    class="grid md:grid-cols-4 gap-4 bg-white overflow-hidden shadow-lg sm:rounded-lg sm:grid-cols-2 sm:grid-row-2"
                >
                    <!-- Search Input -->
                    <input
                        v-model="search"
                        type="text"
                        placeholder="Search by Faculty Name"
                        class="p-6 bg-white border-0 focus:border-2 focus:border-cardinal-red focus:rounded-l-lg text-base focus:outline-none focus:ring-0"
                    />

                    <!-- Department Select -->
                    <div class="p-2">
                        <label
                            for="departmentSelect"
                            class="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Select Department
                        </label>
                        <select
                            id="departmentSelect"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedDepartment"
                        >
                            <option value="">All Departments</option>
                            <option
                                v-for="dept in departments"
                                :value="dept.id"
                                :key="dept.id"
                            >
                                {{ dept.display_name }}
                            </option>
                        </select>
                    </div>

                    <!-- Rank Select -->
                    <div class="p-2">
                        <label
                            for="rankSelect"
                            class="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Select Rank
                        </label>
                        <select
                            id="rankSelect"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedRank"
                        >
                            <option value="">All Rank Levels</option>
                            <option
                                v-for="rank in ranks"
                                :value="rank"
                                :key="rank"
                            >
                                {{ rank }}
                            </option>
                        </select>
                    </div>

                    <!-- Line Select -->
                    <div class="p-2">
                        <label
                            for="lineSelect"
                            class="block mb-2 text-sm font-medium text-gray-900"
                        >
                            Select Line
                        </label>
                        <select
                            id="lineSelect"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-cardinal-red focus:outline-none focus:ring-0 block w-full p-2.5"
                            v-model="selectedLine"
                        >
                            <option value="">All Lines</option>
                            <option
                                v-for="line in lines"
                                :value="line"
                                :key="line"
                            >
                                {{ line }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- Cards Grid -->
        <div class="max-w-screen-2xl mx-auto py-6 px-16">
            <div
                class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6"
            >
                <div
                    v-for="(pcard, index) in facultyProfiles"
                    :key="index"
                    class="bg-white rounded-lg shadow-md overflow-hidden transition transform hover:scale-105 hover:shadow-xl duration-300 flex flex-col h-96"
                >
                    <!-- Image -->
                    <div class="relative h-48">
                        <img
                            v-if="pcard.photo_url"
                            :src="pcard.photo_url"
                            alt="Faculty Photo"
                            class="object-cover w-full h-full"
                        />
                        <img
                            v-else
                            :src="`https://ui-avatars.com/api/?name=${pcard.first_name}+${pcard.last_name}&size=512`"
                            alt="Placeholder"
                            class="object-cover w-full h-full"
                        />
                    </div>

                    <!-- Content -->
                    <div class="p-4 flex flex-col flex-grow">
                        <h2
                            class="text-xl font-semibold text-gray-800 line-clamp-2"
                        >
                            {{ pcard.first_name }} {{ pcard.last_name }}
                        </h2>
                        <p class="text-gray-600 text-base lg:text-sm">
                            <strong>Rank:</strong> {{ pcard.rank }}
                        </p>
                        <p class="text-gray-600 text-base lg:text-sm">
                            <strong>Line:</strong> {{ pcard.line }}
                        </p>
                        <p class="text-gray-600 text-base lg:text-sm">
                            <strong>Department:</strong>
                            {{ pcard.department.name }}
                        </p>

                        <!-- Pushes CAP Profile link to the bottom -->
                        <div class="mt-auto">
                            <a
                                :href="pcard.cap_url"
                                target="_blank"
                                class="text-cardinal-red hover:underline font-semibold"
                            >
                                CAP Profile
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Pagination -->
        <div class="flex justify-center items-center py-8">
            <template v-for="(link, index) in paginateProfiles" :key="index">
                <!-- For mobile, only show Previous and Next -->
                <Link
                    v-if="
                        link.label.toLowerCase().includes('previous') ||
                        link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="px-3 py-2 leading-tight text-gray-500 shadow-lg bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'font-bold text-red-800': link.active,
                    }"
                />

                <!-- For desktop and larger, show all pagination links -->
                <Link
                    v-else-if="
                        !link.label.toLowerCase().includes('previous') &&
                        !link.label.toLowerCase().includes('next')
                    "
                    :href="link.url || ''"
                    v-html="link.label"
                    class="hidden md:block px-3 py-2 leading-tight shadow-lg text-gray-500 bg-white border rounded-lg border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                    :class="{
                        'bg-white': !link.url,
                        'font-bold text-red-800': link.active,
                    }"
                />
            </template>
        </div>
    </div>
</template>

<style>
/* Removed old styles */
</style>
